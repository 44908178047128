<template>
  <aside
    class="menu is-unselectable"
    :class="{ 'is-invisible': !sidebar }"
  >
    <template v-for="(menu, index) in myMenus">
      <ul class="menu-list" :key="`options.${index}`">
        <template v-for="(option, optionIndex) in menu.options">
          <li :id="option.to" :key="`item.${index}.${optionIndex}`">
            <template v-if="option.children">
              <router-link
                :class="{ 'is-active': activeMenus.includes(option.label) }"
                :to="option.to"
              >
                <div>
                  <i v-if="option.icon" class="material-icons">{{ option.icon }}</i>
                  <img class="image__item" v-if="option.image" :src="require('@/assets/images/icon/menu/' + option.image)" alt="option.label">
                  {{ option.label }}
                </div>
                <i class="material-icons" @click="toggleActiveMenu(option.label)">
                  {{ activeMenus.includes(option.label) ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
                </i>
              </router-link>
              <ul v-if="activeMenus.includes(option.label)">
                <li v-for="(child, subIndex) in option.children" :key="`options.${index}.${subIndex}`">
                  <router-link :to="child.to">
                    <i v-if="child.icon" class="material-icons">{{ child.icon }}</i>
                    <img class="image__item" v-if="child.image" :src="require('@/assets/images/icon/menu/' + child.image)" alt="option.label">
                    <div v-else class="simulate_icon" />
                    {{ child.label }}
                  </router-link>
                </li>
              </ul>
            </template>
            <router-link v-else :to="`/${option.to}`">
              <i v-if="option.icon" class="material-icons">{{ option.icon }}</i>
              <img
                v-if="option.image"
                class="image__item"
                :src="require('@/assets/images/icon/menu/' + option.image)"
                :alt="option.label"
              >
              {{ option.label }}
            </router-link>
          </li>
        </template>
      </ul>
    </template>
  </aside>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import menus from '../../lib/menu'

export default {
  name: 'TheSidebar',
  data () {
    return {
      myMenus: []
    }
  },
  computed: {
    ...mapState({
      sidebar: state => state.ui.sidebar,
      activeMenus: state => state.ui.activeMenus,
      activeoption: state => state.ui.activeoption
    })
  },
  methods: {
    ...mapMutations(['toggleActiveMenu']),
    optionActive () {
      const activeElement = document.getElementsByClassName('activeOption')
      if (activeElement.length > 0) activeElement[0].classList.remove('activeOption')
      let element, name, arr
      if (this.activeoption) {
        element = document.getElementById(this.activeoption)
        if (element) {
          name = 'activeOption'
          arr = element.className.split(' ')
          if (arr.indexOf(name) === -1) {
            element.className += ' ' + name
          }
        }
      }
    }
  },
  beforeMount () {
    this.myMenus = menus
  },
  mounted () {
    this.optionActive()
  },
  watch: {
    '$route.path' (newValue, oldValue) {
      this.optionActive()
    }
  }
}
</script>

<style lang="scss" scoped>
  aside {
    background: white;
    height: 100%;
    width: 300px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.11);
  }

  aside.is-invisible {
    display: none;
  }

  .menu-list li {
    min-height: 50px;
  }

  .menu-list li ul {
    border-left: none;
    margin: 0;
    width: 100%;
    padding-left: 0;
  }

  .menu-list a.is-active {
    background-color: transparent;
  }

  .menu-list a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    font-size: 18px;
    line-height: 1.17;
    color: var(--primary-black);
  }
  .menu-list a > div {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .image__item {
    margin: 10px;
    width: 20px;
    image-rendering: crisp-edges;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
  }

  .router-link-active, .menu-list a:hover, .menu-list a.is-active:hover {
    background-color: #d0eac4;
    font-weight: bold;
    img {
      filter: invert(39%) sepia(53%) saturate(452%) hue-rotate(68deg) brightness(98%) contrast(87%);
    }
  }

  .menu-list a i:first-child {
    padding-right: .5rem;
  }

  .menu-list a i:last-child {
    padding-left: .5rem;
  }

  .simulate_icon {
    width: 40px !important;
  }

  @media screen and (max-width: 1023px) {
    aside {
      width: 100%;
      padding-bottom: 1em;
    }
  }
</style>
